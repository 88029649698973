




















































































import Vue from 'vue'
import { ApiResponse, Sale, Requisition, Structure } from '@/interfaces'
import { mapState } from 'vuex'
import { stockStatuses } from '@/formHelpers'

interface Rubrique {
  children?: Rubrique[]
  label: string
  value: string
}

interface Parameter {
  tab?: string
  search?: string
  page?: string
  orderby?: string
  order?: string
  rubriques?: string[]
  stockStatuses?: string[]
  entryDate?: string[]
  itemNumber?: string
  stockId?: string
  sale?: number
  requisition?: number
  expert?: number
  dateOut?: string
  structure?: string
  specialFilter?: string
  sort?: string
}

export default Vue.extend({
  //name: 'ItemFilters',
  props: {
    parentPage: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      addons: 0,
      busy: false,
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      sales: [] as Sale[],
      cascaderProps: { multiple: true },
      stockStatuses,
      filteredStockStatuses: ['entry', 'expertise', 'removed', 'not_shown'] as string[],
      chosenStockStatuses: [['entry'], ['expertise'], ['removed'], ['not_shown']] as string[][],
      filteredRubriques: [] as string[],
      chosenRubriques: [] as string[][],
      rubriques: [],
      entryPeriod: [] as string[],
      query: {},
      saleFilter: null as number | null,
      entryPeriodFilter: [] as string[],
      itemNumberFilter: null as string | null,
      stockIdFilter: null as string | null,
      requisitions: [] as Requisition[],
      requisitionFilter: null as number | null,
      experts: [], // as string[],
      expertFilter: null as number | null,
      dateOutFilter: false,
      structures: [] as Structure[],
      structureFilter: null as string | null,
      specialFilter: null as string | null,
      itemFilters: [
        {
          label: 'Filtres préparation de vente',
          options: [
            {
              value: 'sans_photos',
              label: 'Photos manquantes',
            },
            {
              value: 'sans_estimations',
              label: 'Estimations manquantes',
            },
            {
              value: 'sans_frais_acheteur',
              label: 'Frais acheteur manquant',
            },
            {
              value: 'sans_frais_vendeur',
              label: 'Frais vendeur manquant',
            },
          ],
        },
        {
          label: 'Filtres préparation et après vente',
          options: [
            {
              value: 'tva_none',
              label: 'Non assujettis à la TVA',
            },
            {
              value: 'tva_5_5',
              label: 'Avec TVA 5,5%',
            },
            {
              value: 'tva_10',
              label: 'Avec TVA 10%',
            },
            {
              value: 'tva_20',
              label: 'Avec TVA 20%',
            },
          ],
        },
        {
          label: 'Filtres après de vente',
          options: [
            {
              value: 'drouotonline',
              label: 'Acquéreurs Drouot Online',
            },
            {
              value: 'drouotmoniteur',
              label: 'Acquéreurs Drouot Moniteur',
            },
            {
              value: 'interencheres',
              label: 'Acquéreurs Interenchères',
            },
            {
              value: 'cars',
              label: 'Véhicules immatriculés',
            },
            {
              value: 'plate',
              label: 'Avec numéro de plaquette',
            },
            {
              value: 'expert',
              label: 'Avec expert',
            },
            {
              value: 'adj_5000',
              label: 'Adj. >= 5 000€ (plus value)',
            },
            {
              value: 'adj_750_50000',
              label: '750€ <= Adj. <= 50 000€',
            },
            {
              value: 'adj_50000',
              label: 'Adj. > 50 000€',
            },
            {
              value: 'adagp',
              label: 'ADAGP (case cochée sur le lot)',
            },
          ],
        },
      ],
      sortOptions: [
        {
          value: 'estimationLow',
          label: 'Estimations'
        }, {
          value: 'endPrice',
          label: 'Adjudication'
        }, {
          value: 'reservePrice',
          label: 'Réserve'
        },
      ],
      sortValue: '',
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.fetchRubriques()
        this.addons = newVal.client?.addons ?? 0
      }
    },
    // A vérifier mais ca semble pas poser de régression sur les stocks ???
    // '$route.query': {
    //   deep: true,
    //   immediate: true,
    //   handler(query) {
    //     if (query.search) {
    //       this.search = query.search
    //     }
    //     if (query.itemNumber) {
    //       this.itemNumberFilter = query.itemNumber
    //     }
    //     if (query.stockId) {
    //       this.stockIdFilter = query.stockId
    //     }
    //     if (query.sale) {
    //       this.saleFilter = parseInt(query.sale) ?? null
    //     }
    //     if (query.entryDate) {
    //       this.entryPeriod = query.entryDate as string[]
    //     }
    //     if (query.rubriques) {
    //       this.initRubriques()
    //     }
    //     if (query.stockStatuses) {
    //       this.initStockStatuses()
    //     }
    //     if (query.dateOut) {
    //       this.dateOutFilter = query.dateOut == 'true' ? true : false
    //     }
    //     if (query.structure) {
    //       this.structureFilter = query.structure
    //     }
    //   },
    // },
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("mounted itemfilters")
    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.entryDate) {
      this.entryPeriod = this.$route.query.entryDate as string[]
    }

    if (this.$route.query.itemNumber) {
      this.itemNumberFilter = this.$route.query.itemNumber as string
    }

    if (this.$route.query.stockId) {
      this.stockIdFilter = this.$route.query.stockId as string
    }

    if (this.$route.query.sale) {
      this.saleFilter = parseInt(this.$route.query.sale as string)
    }

    if (this.$route.query.dateOut) {
      this.dateOutFilter = this.$route.query.dateOut == 'true' ? true : false
    }

    switch (this.parentPage) {
      case 'saleItemList':
        this.fetchRequisitions()
        this.fetchStructures()
        this.fetchExperts()
        break
      case 'stockItemList':
        this.fetchSales()
        this.fetchStructures()
        break
      default:
        this.fetchSales()
    }

    if (this.$route.query.requisition) {
      this.requisitionFilter = parseInt(this.$route.query.requisition as string)
    }

    if (this.$route.query.expert) {
      this.expertFilter = parseInt(this.$route.query.expert as string)
    }

    if (this.$route.query.specialFilter) {
      this.specialFilter = this.$route.query.specialFilter as string
    }

    if (this.$route.query.structure) {
      this.structureFilter = this.$route.query.structure as string
    }

    if (this.$route.query.sort) {
      this.sortValue = this.$route.query.sort as string
    }

    if (this.user) {
      this.fetchRubriques()
      this.addons = this.user.client?.addons ?? 0
    }

    if (this.$route.query.rubriques) {
      this.initRubriques()
    }

    this.initStockStatuses()
    //this.updateRoute(false)
  },
  methods: {
    updateRoute(resetPage = true, filteringType = '') {
      // eslint-disable-next-line no-console
      // console.log("updateroute itemfilters")

      if (filteringType === 'stock_status') {
        this.updateStatesFilters()
      } else if (filteringType === 'rubriques') {
        this.updateRubriquesFilters()
      }

      if (resetPage) {
        this.page = '1'
      }

      let query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        rubriques: this.filteredRubriques,
        stockStatuses: this.filteredStockStatuses,
        entryDate: this.entryPeriod,
        itemNumber: this.itemNumberFilter,
        stockId: this.stockIdFilter,
        sale: this.saleFilter,
        requisition: this.requisitionFilter,
        expert: this.expertFilter,
        dateOut: this.dateOutFilter ? 'true' : 'false',
        structure: this.structureFilter,
        specialFilter: this.specialFilter,
        sort: this.sortValue,
      } as Parameter

      if (this.parentPage === 'saleItemList') {
        query = {
          tab: 'item',
          ...query,
        }
        delete query.page
        delete query.sale
        delete query.entryDate
        delete query.dateOut
        this.$emit('setQuery', query)
        let queryString = this.createSearchParams(query).toString()
        // Change the URL whithout reload ==> A finir car au retour tout s'efface... compliqué :/
        history.pushState(null, '', `${this.$route.path}?${queryString}`)
        this.$emit('refreshItemList')
      } else if (this.parentPage === 'requisitionItemList') {
        delete query.page
        delete query.requisition
        delete query.entryDate
        delete query.structure
        this.$emit('setQuery', query)
        let queryString = this.createSearchParams(query).toString()
        // Change the URL whithout reload ==> A finir car au retour tout s'efface... compliqué :/
        history.pushState(null, '', `${this.$route.path}?${queryString}`)
        this.$emit('refreshItemList')
      } else {
        this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
          // This empty catch method is here to avoid the "Avoided redundant navigation" error
        })
      }
    },
    updateRubriquesFilters() {
      this.filteredRubriques = [] as string[]
      this.chosenRubriques.forEach((rubrique: string[]) => {
        rubrique.forEach((value: string) => {
          if (!this.filteredRubriques.includes(value)) {
            this.filteredRubriques.push(value)
          }
        })
      })
    },
    updateStatesFilters() {
      this.filteredStockStatuses = [] as string[]
      this.chosenStockStatuses.forEach((stockStatus: string[]) => {
        stockStatus.forEach((value: string) => {
          if (!this.filteredStockStatuses.includes(value)) {
            this.filteredStockStatuses.push(value)
          }
        })
      })
    },
    fetchSales() {
      if (!this.busy && this.sales.length === 0) {
        this.busy = true
        this.$api
          .get('/sales', {
            params: {
              type: 'simple',
              requisitionId: this.$route.params.requisitionId,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.sales = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    fetchRequisitions() {
      if (this.$route.params.saleId && this.requisitions.length === 0) {
        this.$api
          .get(`/sale/${this.$route.params.saleId}/requisitions-list`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.requisitions = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                'Une erreur est survenue dans la récupération de la liste des requisitions. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      }
    },
    fetchExperts() {
      if (this.$route.params.saleId && this.experts.length === 0) {
        this.$api
          .get(`/sale/${this.$route.params.saleId}/experts-list`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.experts = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                'Une erreur est survenue dans la récupération de la liste des experts. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      }
    },
    fetchRubriques() {
      if (this.rubriques.length === 0 && this.parentPage !== 'stockItemList') {
        const parentModel = this.parentPage === 'saleItemList' ? 'sale' : 'requisition'
        const parentId =
          this.parentPage === 'saleItemList' ? this.$route.params.saleId : this.$route.params.requisitionId

        this.$api
          .get(`/${parentModel}/${parentId}/rubriques-list`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.rubriques = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      } else {
        this.rubriques = this.user.client.config.rubriques
      }
    },
    fetchStructures() {
      if (this.structures.length === 0) {
        this.busy = true
        let url = ''
        let saleId = 0
        if (this.$route.params?.saleId) {
          saleId = parseInt(this.$route.params?.saleId as string)
          url = `/sale/${saleId}/structures`
        } else {
          url = `/structures`
        }
        this.$api
          .get(url)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (saleId) {
              this.structures = apiResponse.data
            } else {
              this.structures = apiResponse.data.map((structure: Structure) => {
                return { structureId: structure.id?.toString(), structureName: structure.name }
              })
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    initRubriques() {
      this.filteredRubriques = []
      this.chosenRubriques = []
      if (typeof this.$route.query.rubriques === 'string') {
        this.filteredRubriques.push(this.$route.query.rubriques)
        this.chosenRubriques.push([this.$route.query.rubriques])
      } else {
        const queryRubriques: string[] = this.$route.query.rubriques as string[]
        let rubriqueArray = [] as string[]
        let parent = {} as Rubrique
        let currentParent = {} as Rubrique
        queryRubriques.forEach((rubrique: string) => {
          parent = this.rubriques.find((rub: Rubrique) => rub.value === rubrique) ?? ({} as Rubrique)
          if (Object.keys(parent).length > 0) {
            currentParent = parent
            if (rubriqueArray.length > 0) {
              rubriqueArray = [] as string[]
            }
            rubriqueArray.push(rubrique)
          } else {
            this.rubriques.forEach((rub: Rubrique) => {
              const child: Rubrique =
                rub.children?.find((rubChild: Rubrique) => rubChild.value === rubrique) ?? ({} as Rubrique)
              if (Object.keys(child).length > 0) {
                rubriqueArray.push(rubrique)
                this.chosenRubriques.push(rubriqueArray)
                rubriqueArray = [] as string[]
                rubriqueArray.push(currentParent.value)
              }
            })
          }
          this.filteredRubriques.push(rubrique)
        })
      }
    },
    initStockStatuses() {
      if (this.parentPage === 'requisitionItemList' || this.parentPage === 'saleItemList') {
        this.filteredStockStatuses = []
        this.chosenStockStatuses = []
      }
      if (this.$route.query.stockStatuses) {
        this.filteredStockStatuses = []
        this.chosenStockStatuses = []
        if (typeof this.$route.query.stockStatuses === 'string') {
          this.filteredStockStatuses.push(this.$route.query.stockStatuses)
          this.chosenStockStatuses.push([this.$route.query.stockStatuses])
        } else {
          const queryStockStatuses: string[] = this.$route.query.stockStatuses as string[]
          queryStockStatuses.forEach((status: string) => {
            this.filteredStockStatuses.push(status)
            this.chosenStockStatuses.push([status])
          })
        }
      }
    },
    filterOptions(node: Record<string, string>, keyword: string) {
      return node.text.toLowerCase().includes(keyword.toLowerCase())
    },
    createSearchParams(params: Parameter) {
      const searchParams = new URLSearchParams()
      Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          values.forEach((value) => {
            searchParams.append(key, value)
          })
        } else if (values != null) {
          let stringValue = values.toString()
          searchParams.append(key, stringValue)
        }
      })
      return searchParams
    },
  },
})
